import { useApi } from '~/api';
import { useRoute } from '#imports';
import { getFirstQueryParam } from '~/utils/common';
import { components, operations } from '~~/api-types';
import { ContentMetricType, createContentMetric } from '~/api/metrics';

export enum ContentType {
  RESOURCE = 'resource',
  SOCIALS = 'socials',
  FILES = 'files',
}

export const useContentTypeLabelMap = () =>
  computed<Record<ContentType, string>>(() => {
    const { t } = useI18n();

    return {
      [ContentType.RESOURCE]: t('content.resource'),
      [ContentType.SOCIALS]: t('content.social-post'),
      [ContentType.FILES]: t('content.file-download'),
    };
  });

export enum ContentSocialType {
  // TWITTER = 'twitter',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
}

export const ContentSocialTypeLabelMap: Record<ContentSocialType, string> = {
  // [ContentSocialType.TWITTER]: 'Twitter',
  [ContentSocialType.FACEBOOK]: 'Facebook',
  [ContentSocialType.LINKEDIN]: 'LinkedIn',
  [ContentSocialType.INSTAGRAM]: 'Instagram Business',
};

export const SocialMaxLengthMap: Record<ContentSocialType, number> = {
  // [ContentSocialType.TWITTER]: 280,
  [ContentSocialType.FACEBOOK]: 6000,
  [ContentSocialType.LINKEDIN]: 3000,
  [ContentSocialType.INSTAGRAM]: 2200,
};

const getAdvocateQueryParams = () => {
  const route = useRoute();

  const advocateCampaignId = getFirstQueryParam(route.query.adc);
  const directSendId = getFirstQueryParam(route.query.dsm);

  const params: Record<string, unknown> = {};

  if (advocateCampaignId) {
    params.advocateCampaignId = advocateCampaignId;
  }
  if (directSendId) {
    params.directSendId = directSendId;
  }

  return params;
};

export type ContentFile = {
  id: number;
  title: string;
  url: string;
  availableForPrint: boolean;
  printMediums?: number[];
};

export type ContentDto = components['schemas']['ContentResponseDto'];

export type PaginatedContentResponse = {
  nodes: ContentDto[];
  totalCount: number;
};

export type ContentSocialDto =
  components['schemas']['ContentSocialResponseDto'];
export type PaginatedContentsDto =
  components['schemas']['PaginatedContentsResponseDto'];
export type GetContentsListQueryParams =
  operations['AppGetContentsListEndpoint_handler']['parameters']['query'];
export type GetSingleContentQueryParams =
  operations['AppGetSingleContentEndpoint_handler']['parameters']['query'];
export type ContentSocialFileDto =
  components['schemas']['ContentSocialFileResponseDto'];

export const apiGetContentsList = (
  hubSlug: string,
  params: GetContentsListQueryParams,
): Promise<PaginatedContentsDto> => {
  const api = useApi();

  return api(`/app/hubs/${hubSlug}/contents`, {
    params,
  });
};

export const apiGetSingleContent = (
  hubSlug: string,
  contentSlug: string,
  params?: GetSingleContentQueryParams,
): Promise<ContentDto> => {
  const api = useApi();

  return api(`/app/hubs/${hubSlug}/contents/${contentSlug}`, {
    params: {
      ...getAdvocateQueryParams(),
      ...params,
    },
  });
};

export type CreateSocialShareUrlBodyDto =
  components['schemas']['CreateSocialShareUrlBodyDto'];
export type AppCreateSocialShareUrlResponse =
  components['schemas']['AppCreateSocialShareUrlResponseDto'];
export type AppCreateSocialShareUrlFromDraftResponse =
  components['schemas']['AppCreateSocialShareUrlFromDraftResponseDto'];

export const apiCreateSocialShareUrl = (
  hubSlug: string,
  contentSlug: string,
  body: CreateSocialShareUrlBodyDto,
): Promise<AppCreateSocialShareUrlResponse> => {
  const api = useApi();

  const requestBody = { ...body };

  return api(`/app/hubs/${hubSlug}/contents/${contentSlug}/social-share-url`, {
    method: 'POST',
    body: requestBody,
  });
};

export type CreateSocialShareUrlFromDraftBodyDto =
  components['schemas']['CreateSocialShareUrlFromDraftBodyDto'];

export const apiCreateSocialShareUrlFromDraft = (
  body: CreateSocialShareUrlFromDraftBodyDto,
): Promise<AppCreateSocialShareUrlFromDraftResponse> => {
  const api = useApi();

  return api(`/app/drafts/social-share-url`, {
    method: 'POST',
    body,
  });
};

export const apiMarkContentCtaButtonClicked = (
  hubSlug: string,
  contentSlug: string,
) => {
  return createContentMetric(
    hubSlug,
    contentSlug,
    ContentMetricType.ACTION_BUTTON,
  );
};

export type PaginatedContentsResponseDto =
  components['schemas']['PaginatedContentsResponseDto'];
export type GetCustomizableContentsListQueryParams =
  operations['AppGetCustomizableContentsListEndpoint_handler']['parameters']['query'];

export const apiGetCustomizableContentsList = (
  params: GetCustomizableContentsListQueryParams,
): Promise<PaginatedContentsResponseDto> => {
  const api = useApi();

  return api(`/app/contents/customizable`, {
    params,
  });
};

export const apiGetSingleCustomizedContent = (
  contentSlug: string,
): Promise<ContentDto> => {
  const api = useApi();

  return api(`/app/contents/${contentSlug}/customizable`);
};

export type GetContentSocialDraftsListParams =
  operations['AppGetContentSocialDraftsListEndpoint_handler']['parameters']['query'];

export type PaginatedContentSocialDraftsResponseDto =
  components['schemas']['PaginatedContentSocialDraftsResponseDto'];

export type ContentSocialDraftDto =
  components['schemas']['ContentSocialDraftResponseDto'];

export const apiGetContentSocialDraftsList = (
  params: GetContentSocialDraftsListParams,
): Promise<PaginatedContentSocialDraftsResponseDto> => {
  const api = useApi();

  return api(`/app/drafts`, {
    params,
  });
};

export type CreateContentSocialDraftBodyDto =
  components['schemas']['CreateContentSocialDraftBodyDto'];

export const apiCreateContentSocialDraft = (
  body: CreateContentSocialDraftBodyDto,
): Promise<ContentSocialDraftDto> => {
  const api = useApi();

  return api(`/app/drafts`, {
    method: 'POST',
    body,
  });
};

export type UpdateSocialDraftBodyDto =
  components['schemas']['UpdateSocialDraftBodyDto'];

export const apiUpdateContentSocialDraft = (
  draftId: number,
  body: UpdateSocialDraftBodyDto,
): Promise<ContentSocialDraftDto> => {
  const api = useApi();

  return api(`/app/drafts/${draftId}`, {
    method: 'PATCH',
    body,
  });
};

export const apiDeleteContentSocialDraft = (
  draftId: number,
): Promise<CreateContentSocialDraftBodyDto> => {
  const api = useApi();

  return api(`/app/drafts/${draftId}`, {
    method: 'DELETE',
  });
};
